@import url("https://fonts.googleapis.com/css2?family=DynaPuff:wght@400..700&family=Oswald:wght@200..700&family=Pacifico&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
/* .product-card {

  border-radius: 5px;
  margin-top: 3rem;
  margin-left: 3rem;
  margin-bottom: 3rem;
  width: 20rem;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  font-family: "Poppins", serif;
} */

.product-card img {
  width: 20rem;
  border-radius: 10px 10px 0 0;
}
.product-title {
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 600;
}

.product-author {
  font-size: 16px;
  color: #606060;
  margin-bottom: 10px;

}

.product-description {
  margin-bottom: 10px;
  font-size: 13px;
  color: #929292;
}
.card input::placeholder {
  font-size: 14px;
}
.card textarea::placeholder {
  font-size: 14px;
}

.card tfoot {
  width: 100%;
  display: block;
  float: left;
}
.card tfoot tr {
  width: 100%;
  float: left;
}
.card tfoot tr td {
  width: 100%;
}
.product-price {
  display: flex;
  align-items: center;
  margin-bottom: 13px;
  
}
.price-section span {
  white-space: nowrap;
}



.product-price-current {
  font-size: 16px;
  font-weight: bold;
  margin-right: 10px;
}

.product-price-original {
  font-size: 14px;
  color: #888;
  text-decoration: line-through;
}

.product-actions {
  display: flex;
}

.product-button {
  width: 7.5rem;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  margin-bottom: 1rem;
  border-radius: 4px;
}
.product-button.btn1 {
  border: 1px solid #316BFF;
  color: #316BFF;
  background-color: white;
  width: 49%;
  margin-right: 2%;
  font-size: 13px;
  text-decoration: none;
  margin-bottom: 0;
  text-align: center;
  transition: all 0.5s ease-in-out;
  padding-inline: 10px;


}
.store-wrap input {
  cursor: pointer;
}
.product-button.btn1:hover{
  border: 1px solid #316BFF;
  background-color: #316BFF;
  color: white;
}
.product-button.btn2 {
  background-color: #316BFF;
  border: 1px solid #316BFF;

  width: 49%;
  font-size: 13px;
  text-decoration: none;
  margin-bottom: 0;
  text-align: center;
  transition: all 0.5s ease-in-out;
}
.product-button.btn2:hover{
  border: 1px solid #316BFF;
  color: #316BFF;
  background-color: white;
}

.product-card {
  border-radius: 10px;
  margin-left: 2rem;
  margin-bottom: 0.5rem;
  width: 18.75rem !important;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
  font-family: "Plus Jakarta Sans", sans-serif;
  padding-inline: 0 !important;
}
 .product-card {
  position: relative;
  padding-bottom: 60px;
}
 .product-card .product-actions {
  position: absolute;
  bottom: 20px;
  width: 100%;
  left: 0;
  padding-inline: 20px;
}
.store-wrap .store-items {
  padding-bottom: 3rem;
}

.product-card .product-info {
  margin-top: 1rem;
  padding-inline: 20px;
}
