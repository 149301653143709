/*header  */
.head-wrap { float: left; width: 100%; -webkit-box-shadow: 0 1px 8px 0 rgba(0,0,0,0.2); box-shadow: 0 1px 8px 0 rgba(0,0,0,0.2); background: #f3f3f8; }
.head-wrap .head-nav { display: flex; flex-wrap: wrap; width: 100%; justify-content: space-between; align-items: center; padding: 10px 0; }
.head-wrap .head-nav nav.bg-body-tertiary.navbar.navbar-expand-lg { justify-content: space-between!important; width: 100%; display: flex!important; background: none!important; padding: 0!important; }
.head-wrap .head-nav nav.bg-body-tertiary.navbar.navbar-expand-lg #basic-navbar-nav { flex-grow: inherit; width: auto; justify-content: center; }
.head-wrap .head-nav a { display: inline-block; }
.head-wrap .head-nav .brand-logo { display: inline-block; }
.head-wrap .head-nav ul.menu-ul { display: flex; align-items: center; justify-content: center; padding: 0; margin: 0; }
.head-wrap .head-nav ul.menu-ul li { display: inline-block; list-style: none; margin: 0 15px; }
.head-wrap .head-nav ul.menu-ul li a { display: inline-block; padding: 5px 15px; font-size: 18px; color: #000; text-decoration: none; transition: ease all 0.5s; }
.head-wrap .head-nav ul.menu-ul li a:hover { color: #00aeef; }
.head-wrap .head-nav ul.login-ul { display: flex; align-items: center; justify-content: center; padding: 0; margin: 0; }
.head-wrap .head-nav .navbar-nav a.nav-link { display: inline-block; font-size: 16px; color: #000; text-decoration: none; padding: 0 10px; transition: ease all 0.5s; margin: 0 15px; white-space: nowrap;}
.head-wrap .head-nav .login-ul.navbar-nav a.nav-link { margin: 0 0 0 10px; display: inline-block; padding: 0 5px; font-size: 16px; color: #3686f7; text-decoration: none; transition: ease all 0.5s; position: relative; }
.head-wrap .head-nav .login-ul.navbar-nav a.nav-link:last-child::before { position: absolute; left: -5px; width: 1px; height: 22px; background: #3686f7; content: ""; top: 2px; }
.head-wrap .head-nav .login-ul.navbar-nav a.nav-link:last-child { margin-right: 0; padding-right: 0; }
.head-top { float: left; width: 100%; background: #222; }
.head-wrap .head-nav .navbar-nav a.nav-link:hover { color: #3686f7; }
.head-wrap .head-nav .login-ul.navbar-nav a.nav-link:hover { color: #000; }
.head-top .head-top-row { display: flex; flex-wrap: wrap; justify-content: space-between; width: 100%; align-items: center; }
.head-top .head-top-row .head-top-left { display: inline-block; }
.head-top .head-top-row .head-top-left p { color: #fff; margin: 0; font-size: 12px; font-weight: 400; }
.head-top .head-top-row  .head-top-right { display: inline-block; }
.head-top .head-top-row .head-top-right ul.social-ul { display: flex; align-items: center; justify-content: flex-end; margin: 0; }
.head-top .head-top-row .head-top-right ul.social-ul li { display: inline-block; margin: 0 0 0 5px; }
.head-top .head-top-row .head-top-right ul.social-ul li a { display: inline-block; padding: 5px; transition: ease all 0.5s; }
/* .stiky-header .head-top .head-top-row .head-top-right ul.social-ul li a { padding: 2px 5px; } */
.body-container { float: left; width: 100%; padding-top: 260px; position: relative; }
.detailpageclass .body-container {padding-top: 115px;}
.search-wrap { float: left; width: 100%; padding: 50px 0; }
.head-top .head-top-row .head-top-right ul.social-ul li a:hover img { opacity: 0.6; }
.head-outer .search-form.top-serch { width: 60%; margin: 0 auto; }
.head-outer { z-index: 9999; float: left; width: 100%; position: fixed; left: 0; top: 0; transition: ease all 0.5s; }
.head-outer.stiky-header { position: fixed; left: 0; top: 0; }
.stiky-header .head-wrap .head-nav nav.bg-body-tertiary.navbar.navbar-expand-lg  #basic-navbar-nav { margin-left: 35%; }

.head-outer.stiky-header .search-wrap { padding: 0; position: relative; margin-top: -52px; margin-left: 32%; width: 20%; }

.head-outer.stiky-header .search-wrap form.search-form.top-serch { width: 100%; }
/* .stiky-header .head-wrap .head-nav { padding: 5px 0; } */

.body-container .home-page-outer .row.row-two {align-items: start;}
.body-container .home-page-outer .row.row-two .col-md-3 {position: sticky; top: 100px;}

.head-outer.stiky-header .search-wrap .search-form input.form-control { width: 70%; height: 36px; border-radius: 5px 0 0 5px; }
.head-outer.stiky-header .search-wrap .search-form input.form-control::placeholder { font-size: 14px; }
.head-outer.stiky-header .search-wrap .container { width: 100%!important; max-width: 100%!important; padding: 0; }
.head-outer.stiky-header .search-form label.custom-select select.form-select { height: 36px; line-height: 36px; }
.head-outer.stiky-header .search-form button.search-btn { height: 36px; background-size: 20px; }
.news-dtl-wrap p { font-size: 16px; float: left; width: 100%; color: #000; }
.stiky-header .search-form .custom-select:after { top: 5px; }
.stiky-header .head-top .head-top-row .head-top-right ul.social-ul li a img { margin-top: -2px; }
.news-dtl-wrap iframe { float: left; width: 100%; }
.head-outer .head-wrap .container .head-nav nav.bg-body-tertiary.navbar .navbar-brand { width: 23%; }
.stiky-header .head-wrap .head-nav nav.bg-body-tertiary.navbar.navbar-expand-lg #basic-navbar-nav .navbar-nav a.nav-link { margin: 0 10px; }
[dir="rtl"]  .head-top .head-top-row .head-top-right ul.social-ul { justify-content: left; padding-left: 0!important; }
[dir="rtl"] .head-wrap .head-nav .login-ul.navbar-nav a.nav-link { margin: 0 10px 0 0; }
[dir="rtl"] .head-top .head-top-row .head-top-right ul.social-ul li { margin: 0 5px 0 0; }
@media (max-width: 1480px) {
  .head-outer.stiky-header .search-wrap { margin-top: -52px; margin-left: 25%; width: 27%; }
  [dir="rtl"] .head-outer.stiky-header .search-wrap { margin-left: 0; margin-right: 25%; }
  .news-dtl-wrap .news-dtl-head .social-newsdtl { top: 42vh; left: 1.5vw; }
}

@media (max-width: 1320px) {
  .stiky-header .head-wrap .head-nav nav.bg-body-tertiary.navbar.navbar-expand-lg #basic-navbar-nav { margin-left: 35%; width: 40%; }
  [dir="rtl"] .stiky-header .head-wrap .head-nav nav.bg-body-tertiary.navbar.navbar-expand-lg #basic-navbar-nav { margin-left: 0; margin-right: 35%; }
  .stiky-header .head-wrap .head-nav nav.bg-body-tertiary.navbar.navbar-expand-lg #basic-navbar-nav .navbar-nav a.nav-link { margin: 0 5px; }
  .stiky-header .head-wrap .head-nav nav.bg-body-tertiary.navbar.navbar-expand-lg .login-ul.navbar-nav { width: 18%; }
  .head-outer.stiky-header .search-wrap { margin-top: -46px; }
}
@media (max-width: 1280px) {
  .stiky-header .head-wrap .head-nav nav.bg-body-tertiary.navbar.navbar-expand-lg #basic-navbar-nav { margin-left: 35%; width: 44%; }
  [dir="rtl"] .stiky-header .head-wrap .head-nav nav.bg-body-tertiary.navbar.navbar-expand-lg #basic-navbar-nav { margin-left: 0; margin-right: 35%; }
  .head-outer.stiky-header .search-wrap .search-form input.form-control { font-size: 14px; padding: 0 10px; }
  .head-outer.stiky-header .search-wrap .search-form input.form-control::placeholder { font-size: 14px; }
}
@media (max-width: 1180px) {
  .news-dtl-wrap .news-dtl-head .social-newsdtl { top: 42vh; left: 1.5vw; }
  .head-wrap .head-nav .navbar-nav a.nav-link { font-size: 16px; padding: 0 7px; margin: 0 5px; }
  .stiky-header .head-wrap .head-nav nav.bg-body-tertiary.navbar.navbar-expand-lg #basic-navbar-nav { margin-left: 26%; }
  [dir="rtl"] .stiky-header .head-wrap .head-nav nav.bg-body-tertiary.navbar.navbar-expand-lg #basic-navbar-nav { margin-left: 0; margin-right: 26%; }
  .head-outer.stiky-header .search-wrap .search-form input.form-control { width: 63%; padding: 0 10px; }
  .head-outer.stiky-header .search-form label.custom-select select.form-select { padding: 0 15px 0 10px; font-size: 16px; }
  .stiky-header .search-form .custom-select:after { top: 7px; font-size: 75%; padding: 3px; right: 4px; }
  .head-outer.stiky-header .search-form button.search-btn { padding: 0 10px; background-size: 17px; }
  .head-wrap .head-nav .login-ul.navbar-nav a.nav-link { font-size: 15px; }
  .head-outer.stiky-header .search-wrap { margin-left: 23%; }
  [dir="rtl"] .head-outer.stiky-header .search-wrap { margin-left: 0; margin-right: 23%; }
  .stiky-header .head-wrap .head-nav nav.bg-body-tertiary.navbar.navbar-expand-lg #basic-navbar-nav .navbar-nav a.nav-link { margin: 0; }
  .stiky-header .head-wrap .head-nav nav.bg-body-tertiary.navbar.navbar-expand-lg .login-ul.navbar-nav { width: 18%; }
}

@media (max-width:991px) {
  .head-wrap .head-nav button.navbar-toggler { position: absolute; right: 15px; border: none!important; outline: none!important; box-shadow: none!important; }
  .head-wrap .head-nav button.navbar-toggler.collapsed span.navbar-toggler-icon { color: #000; width: 28px!important; width: 28px!important; position: relative; border: none; transition: all 300ms linear; background: url(../images/nav-open-btn.svg) no-repeat center center!important; background-size: 22px!important; }
  .head-wrap .head-nav button.navbar-toggler  span.navbar-toggler-icon { color: #000; width: 28px!important; width: 28px!important; position: relative; border: none; background: url(../images/nav-close-btn.svg) no-repeat center center!important; background-size: 22px!important; }
  .login-ul.navbar-nav a.nav-link { width: auto; }
  .head-wrap .head-nav .login-ul.navbar-nav a.nav-link { font-size: 14px; }
  .head-wrap .head-nav nav.bg-body-tertiary.navbar.navbar-expand-lg #basic-navbar-nav { position: absolute; width: 100%; top: 109px; background: #fff; z-index: 99; }
  .head-outer .head-wrap { position: static; }
  .head-outer .head-wrap .container { position: static; width: 100%!important; max-width: 100%!important; padding: 0; }
  .head-outer .head-wrap .container .head-nav { position: static; }
  .head-outer .head-wrap .container .head-nav nav.bg-body-tertiary.navbar.navbar-expand-lg { position: static; }
  .head-wrap .head-nav nav.bg-body-tertiary.navbar.navbar-expand-lg #basic-navbar-nav .navbar-nav a.nav-link { font-size: 17px; padding: 10px 30px; margin: 0; }
  .head-outer .head-wrap .container .head-nav nav.bg-body-tertiary.navbar .navbar-brand { margin-left: 3%; padding-left: calc(var(--bs-gutter-x)* .5); }
  .login-ul.navbar-nav { padding-right: calc(var(--bs-gutter-x)* .5); margin-right: 75px; flex-direction: row; }
  .body-container .main-menu button.navbar-toggler { display: none; }
  .head-wrap .head-nav .login-ul.navbar-nav a.nav-link:last-child::before { height: 18px; left: -6px; top: 2px; }
  .search-wrap { padding: 30px 0; }
  .body-container .main-menu { padding-top: 20px; padding-bottom: 20px; }
  .container { width: 100%!important; max-width: 100%!important; }
  .head-outer.stiky-header .search-wrap { margin-left: 33%; width: 40%; margin-top: -50px; }
  [dir="rtl"] .head-outer.stiky-header .search-wrap { margin-left: 0; margin-right: 33%; }
  .stiky-header .head-wrap .head-nav nav.bg-body-tertiary.navbar.navbar-expand-lg #basic-navbar-nav { margin-left: 0; }
  [dir="rtl"] .stiky-header .head-wrap .head-nav nav.bg-body-tertiary.navbar.navbar-expand-lg #basic-navbar-nav { margin-left: auto; margin-right: 0; }
  .head-wrap .head-nav button.navbar-toggler { right: 0; }
  .stiky-header .head-wrap .head-nav nav.bg-body-tertiary.navbar.navbar-expand-lg #basic-navbar-nav { width: 100%!important; }
  [dir="rtl"] .head-wrap .head-nav button.navbar-toggler { right: auto; left: 0; }
  [dir="rtl"] .login-ul.navbar-nav { margin-right: 0; margin-left: 75px; }
  [dir="rtl"] .head-outer .head-wrap .container .head-nav nav.bg-body-tertiary.navbar .navbar-brand {
    padding-right: 15px;
}
}

@media screen and (min-width:768px) and (max-width:991px) {
}

@media (max-width:767px) {
  .head-wrap .head-nav nav.bg-body-tertiary.navbar.navbar-expand-lg #basic-navbar-nav { top: 96px; }
  .head-outer.stiky-header .search-wrap { margin-left: 30%; width: 40%; margin-top: -44px; }
  [dir="rtl"] .head-outer.stiky-header .search-wrap { margin-left: 0; margin-right: 30%; }
  .stiky-header .head-wrap .head-nav nav.bg-body-tertiary.navbar.navbar-expand-lg .login-ul.navbar-nav { width: auto; }
  .head-wrap .head-nav button.navbar-toggler { right: 0; }
  .body-container .home-page-outer .row.row-two .col-md-3 {position: relative; top: inherit;}
}

@media (max-width:600px) {
  .head-outer.stiky-header .search-wrap { position: static; margin-top: 0; margin-left: inherit; width: 100%; display: none; }
}

@media (max-width:425px) {
}

@media (max-width:414px) {
  .head-wrap .head-nav .login-ul.navbar-nav a.nav-link { font-size: 13px; }
  [dir="rtl"] .login-ul.navbar-nav { margin-right: 0; margin-left: 45px; }
  .row.row-two .col-md-9 .row.split-row.row-three .col-md-12.block-col1 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-cnt h2 {
    font-size: 16px;
}
}
@media (max-width:375px) {
  .head-wrap .head-nav button.navbar-toggler { right: 0; }
  .head-top .head-top-row .head-top-right ul.social-ul li { margin: 0; }
  .head-top .head-top-row .head-top-right ul.social-ul li a { padding: 3px; }
  .head-top .head-top-row .head-top-left p { font-size: 11px; }
  .login-ul.navbar-nav { margin-right: 50px; }
  [dir="rtl"] .head-top .head-top-row .head-top-right ul.social-ul li { margin: 0; }
  .stiky-header .head-top .head-top-row .head-top-right ul.social-ul li a {
    padding: 2px 3px;
}
}


