.signInWrapp {
    width: 100%;
    display: block;
    float: left;
    padding: 10px 3% 40px;
}

.signInWrapp .signInContainer {
    width: 100%;
    margin: 0 auto 15px;
    justify-content: center;
}

.signInWrapp .signInContainer .col-md-4 {
    width: 100%;
    padding: 20px 4% 14px;
    background: #f3f3f8;
    margin: 0 auto 0;
    border-radius: 8px;
    max-width: 730px;
    border: 1px solid #ddd;
}

.signInWrapp .signInContainer .col-md-4 .signIn-head {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    margin: 10px 0 28px;
}

.signInWrapp h3 {
    font-size: 22px;
    font-weight: 700;
    margin: 0 0 0;
}

.signInWrapp .signInContainer .col-md-4 .signIn-head img {
    width: 44%;
    display: block;
    float: right;
}

.signInWrapp .signInContainer .signInForm {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    float: left;
}

.signInWrapp .signInForm .formfield,.signInWrapp .signInForm .input-field {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    float: left;
    padding: 5px 0 0;
}

.signInWrapp .signInForm .input-field {
    width: 48%;
    display: inline-block;
    float: left;
    margin: 0 4% 0 0;
}

.signInWrapp .signInForm .input-field:nth-child(2n) {
    float: right;
    margin-right: 0;
}

.signInWrapp .signInContainer .signInForm label {
    font-size: 16px;
    display: block;
}

.signInWrapp .signInContainer .signInForm input:not([type='radio'],[type='checkbox']) {
    width: 100%;
    height: 48px;
    display: block;
    float: left;
    padding: 0 9px 0;
    margin: 5px 0 0;
    border: none;
    background: #ffffff;
    font-size: 15px;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    box-shadow: none;
    outline: none;
    border-radius: 4px;
}
.signInWrapp .signInContainer .signInForm input[type="checkbox"],.signInWrapp .signInContainer .signInForm input[type="radio"] {
    width: 18px;
    height: 18px;
    border-radius: 0;
    box-shadow: none;
    outline: none;
}

.signInWrapp .signInForm .input-field .mb-3 {
    width: 100%;
}

.signInWrapp .signInForm .input-field .form-check {
    width: 100%;
    margin: 0 0 14px;
}

.signInWrapp .signInForm .input-field .form-check label {
    padding: 1.5px 0 0 8px;
}

.signInWrapp .signInContainer .signInForm .signInBtn1 {
    width: 48%;
    height: inherit;
    font-size: 14px;
    line-height: normal;
    letter-spacing: 0.85px;
    padding: 12px 15px;
    margin: 10px auto 0;
    background: #3686f7;
    border: 1px solid #3686f7;
    color: #ffffff;
    border-radius: 30px;
}

.signInWrapp .signInContainer .signInForm .signInBtn2 {
    width: 120px;
    height: inherit;
    background: none;
    color: #3686f7;
    border: 1px solid #3686f7;
    border-radius: 30px;
    font-size: 14px;
    line-height: normal;
    letter-spacing: 0.85px;
    padding: 10px 20px;
    margin: 10px 0 0;
}

.signInWrapp .signInForm .signInFooter, .signInWrapp  .signInFooter {
    width: 100%;
    display: block;
    float: left;
    margin: 30px 0 0;
    padding: 20px 0 0;
    border-top: 1px solid #ddd;
}

.signInWrapp .signInContainer .signInForm .signInBtn1:hover {
    background: #222;
    color: #ffffff;
    border-color: #222;
}

.signInWrapp .signInContainer .signInForm .signInBtn2:hover {
    background: #3686f7;
    color: #ffffff;
    border-color: #3686f7;
}

.signInWrapp .signInForm .signInFooter p, .signInWrapp  .signInFooter p {
    color: #888888;
    font-size: 15px;
    margin: 0 0 15px;
}

.signInWrapp .signInForm .signInFooter p a, .signInWrapp  .signInFooter p a {
    display: inline-block;
    text-decoration: none;
    padding: 0 0 0 10px;
    color: #3686f7;
}

.signInWrapp .signInForm .signInFooter p a:hover, .signInWrapp  .signInFooter p a:hover {
    color: #888888;
}


.signInWrapp .signInContainer .col-md-6.second-form {
    width: 100%;
    padding: 20px 4% 14px;
    background: #f3f3f8;
    margin: 0 auto 0;
    border-radius: 8px;
    max-width: 730px;
    border: 1px solid #ddd;
}

.signInWrapp .signInContainer .col-md-6.second-form .signIn-head {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 20px 0 28px;
}

.signInWrapp .signInContainer .col-md-6.second-form .signIn-head img {
    width: 34%;
    display: block;
    float: right;
    margin: 0 0 0 6%;
}

.signInWrapp .signInContainer .col-md-6.second-form .signInForm {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.signInWrapp .signInContainer .col-md-6.second-form .input-file {
    width: 100%;
    display: block;
}

.signInWrapp .signInContainer .col-md-6.second-form  .input-field {
    width: 48%;
    margin: 0 0 0;
}

.signInWrapp .signInContainer .col-md-6.second-form .input-field .css-b62m3t-container {
    width: 100%;
    cursor: pointer;
}

.signInWrapp .signInContainer .col-md-6.second-form .input-field .css-b62m3t-container .css-1u9des2-indicatorSeparator {
    display: none;
}

.signInWrapp .signInContainer .col-md-6.second-form .input-field .css-b62m3t-container .css-1xc3v61-indicatorContainer {
    padding: 8px 12px 8px 8px;
}

.signInWrapp .signInContainer .col-md-6.second-form .input-field .css-b62m3t-container .css-1xc3v61-indicatorContainer .css-tj5bde-Svg {fill: #000;}

.signInWrapp .signInContainer .col-md-6.second-form .input-field:nth-last-child(4) {
    width: 100%;
}

.signInWrapp .signInContainer .col-md-6.second-form .signInBtn2 {
    width: 48%;
}

.signInWrapp .signInContainer .col-md-6.second-form .signInBtn1 {
    width: 48%;
}

.signInWrapp .signInContainer .col-md-6.second-form .input-file .mb-3 {
    width: 50%;
}

.signInWrapp .signInContainer .col-md-6.second-form .input-file .mb-3 label.form-label {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.signInWrapp .signInContainer .col-md-6.second-form .input-file input[type="file"] {
    width: 50%;
    float: right;
    display: inline-block;
    background: none;
    height: inherit;
    margin: 0;
    padding: 0 0 0 12px;
    cursor: pointer;
}

.signInWrapp .signInContainer .col-md-6.second-form .input-file .input-group.has-validation {
    width: 50%;
    float: left;
    min-height: 150px;
    min-width: 150px;
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: row-reverse; */
    position: relative;
    margin: 0 0 8px;
    cursor: pointer;
}

.signInWrapp .signInContainer .col-md-6.second-form .input-file .input-group.has-validation:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background: url(../images/register-placeholder.png) #ffffff no-repeat center center;
    left: 0;
    border-radius: 8px;
}

.signInWrapp .signInContainer .col-md-6.second-form .input-file .input-group.has-validation button {
    display: none;
}

.signInWrapp .signInContainer .col-md-6.second-form .input-file .input-group.has-validation > div {
    width: 100%;
    float: left;
    display: inline-block;
    z-index: 9;
    margin: 0;
}

.signInWrapp .signInContainer .col-md-6.second-form .input-file .input-group.has-validation img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.signInWrapp .signInContainer .signInForm label .upload-group {
    width: 46%;
    float: right;
    display: inline-block;
    margin: 0 0 0 4%;
}

.signInWrapp .signInContainer .signInForm label .upload-group .upload-detail {
    width: 100%;
    display: block;
}

.signInWrapp .signInContainer .signInForm label span {
    display: block;
    margin: 0 0 6px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 150px;
}

.signInWrapp .signInContainer .col-md-6.second-form .input-field .css-b62m3t-container .css-13cymwt-control {
    height: 48px;
    border-color: transparent;
}

.signInWrapp .signInContainer .col-md-6.second-form .input-field .css-b62m3t-container .css-13cymwt-control input {
    height: 100%;
}

.signInWrapp .signInContainer .col-md-6.second-form .input-field textarea {
    resize: none;
    height: 135px;
    max-height: 155px;
}

.signInWrapp .signInContainer .col-md-6.second-form .input-field .react-datepicker__tab-loop {
    z-index: 9;
}

@media (max-width:414px) {

    .signInWrapp .signInContainer .signInForm .signInBtn1, 
    .signInWrapp .signInContainer .signInForm .signInBtn2 {width: 48%;}

    .signInWrapp .signInForm .signInFooter p a, .signInWrapp  .signInFooter p a {padding: 0;}

    .signInWrapp .signInContainer .signInForm .signInBtn1 {width: 100%;}

}

@media (max-width:525px) {

    .signInWrapp .signInForm .input-field {width: 100%; margin: 0;}

}