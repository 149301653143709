.loginWrapp {
    width: 100%;
    display: block;
    float: left;
    padding: 60px 3% 40px;
}
.customer-login {
    width: auto;
    margin-left: 0rem;
    border-radius: 0px;
    margin-top: 0;
    padding: 1.2rem;
    border-radius: 10px;
}

.loginWrapp .loginContainer {
    width: 100%;
    margin: 0 auto 16px;
    justify-content: center;
}
.customer-login input{margin-top: 0;}
.customer-login input {
    display: block;
    margin-left: 0rem;
    /* padding: 5px;
    margin-top: 1rem;
    border-radius: 5px;
    border: 1px solid grey;
    outline: none; */
}
.customer-login h2 {
    margin-left: 0rem;
    padding-top: 0rem;
}
.customer-login form {
    padding-inline: 10px;
}
.loginWrapp .loginContainer .col-md-4 {
    width: 100%;
    padding: 14px 4%;
    background: #f3f3f8;
    margin: 0 auto 0;
    border-radius: 8px;
    max-width: 730px;
    border: 1px solid #ddd;
}
.customer-login button{margin-left: 0;
        margin-bottom: 0;
}
.loginWrapp .loginContainer .col-md-4 .log-head {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: end;
    margin: 10px 0 10px;
}
.customer-login p {
    margin-left: 0;
}
.customer-login button{width: 100%;}

.customer-login input{border: 1px solid #dee2e6 !important;}

.loginWrapp h3 {
    font-size: 22px;
    font-weight: 700;
    margin: 0 0 0;
}

.loginWrapp .loginContainer .col-md-4 .log-head img {
    width: 44%;
    display: block;
    float: right;
}
.customer-login button{height: auto;}
.customer-login button:hover{
    background: transparent;
    color: #3686f7;
    border: 1px solid #3686f7;
}
.customer-login h2{margin-top: 12px;}

.loginWrapp .loginContainer .loginForm {
    width: 100%;
    display: block;
    float: left;
}

.loginWrapp .loginContainer .loginForm .input-field {
    width: 49%;
    display: inline-block;
    float: left;
}
.cart-wrap .top-action,
.store-wrap .top-action  
 {
    margin-bottom: 30px;
}
.cart-wrap .top-action a,
.store-wrap .top-action a
 {
    background: #3686f7;
    padding: 10px 20px;
    border-radius: 7px;
    color: #fff;
    text-decoration: none;
    transition: all 0.5s ease-in-out;
    border: 1px solid #3686f7;
    z-index: 9999;
    position: relative;

}
.cart-wrap .top-action a:hover,
.store-wrap .top-action a:hover
{
    background: transparent;
    color: #3686f7;
    border: 1px solid #3686f7;
}


a.frgtpw {
    color: grey;
    text-decoration: none;
    font-size: 14px;
}

.loginWrapp .loginContainer .loginForm .input-field:nth-child(2) {
    float: right;
}

.loginWrapp .loginForm .formfield {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    float: left;
    padding: 20px 0 0;
}

.loginWrapp .loginContainer .loginForm label {
    font-size: 16px;
    display: block;
}

.loginWrapp .loginContainer .loginForm input {
    width: 100%;
    height: 42px;
    display: block;
    float: left;
    padding: 0 9px 0;
    margin: 5px 0 0;
    border: none;
    background: #ffffff;
    font-size: 15px;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    box-shadow: none;
    outline: none;
}

.loginWrapp .loginForm .frgtpw {
    color: #888888;
    font-size: 15px;
    float: left;
    text-decoration: none;
    margin: 6px 0 0;
}

.loginWrapp .loginForm .frgtpw:hover {
    color: #3686f7;
}

.loginWrapp .loginContainer .loginForm input.loginBtn1 {
    width: 170px;
    height: inherit;
    font-size: 14px;
    line-height: normal;
    letter-spacing: 0.85px;
    padding: 10px 20px;
    margin: 10px 0 0;
    background: #3686f7;
    border: 1px solid #3686f7;
    color: #ffffff;
    border-radius: 30px;
}

.loginWrapp .loginContainer .loginForm input.loginBtn2 {
    width: 120px;
    height: inherit;
    background: none;
    color: #3686f7;
    border: 1px solid #3686f7;
    border-radius: 30px;
    font-size: 14px;
    line-height: normal;
    letter-spacing: 0.85px;
    padding: 10px 20px;
    margin: 10px 0 0;
}

.loginWrapp .loginForm .loginFooter {
    width: 100%;
    display: block;
    float: left;
    margin: 30px 0 0;
    padding: 20px 0 0;
    border-top: 1px solid #ddd;
}

.loginWrapp .loginContainer .loginForm input.loginBtn1:hover {
    background: none;
    color: #3686f7;
    border-color: #3686f7;
}

.loginWrapp .loginContainer .loginForm input.loginBtn2:hover {
    background: #3686f7;
    color: #ffffff;
    border-color: #3686f7;
}

.loginWrapp .loginForm .loginFooter p {
    color: #888888;
    font-size: 15px;
    margin: 0 0 15px;
}

.loginWrapp .loginForm .loginFooter p a {
    display: inline-block;
    text-decoration: none;
    padding: 0 0 0 10px;
    color: #3686f7;
}

.loginWrapp .loginForm .loginFooter p a:hover {
    color: #888888;
}

.loginWrapp .loginForm .formfield .loginBtn1 {
    width: 50%;
    border-radius: 30px;
    background: #3686F7;
    border-color: #3686F7;
    padding: 12px 15px;
}

.loginWrapp .loginForm .formfield .loginBtn1:hover {
    background: #222;
    border-color: #222;
}

.loginWrapp .loginContainer .alert-success.show p {
    margin: 0 0 0;
}

@media (max-width:414px) {

    .loginWrapp .loginContainer .loginForm input.loginBtn1, 
    .loginWrapp .loginContainer .loginForm input.loginBtn2 {width: 48%;}

    .loginWrapp .loginForm .loginFooter p a {padding: 0;}

    .loginWrapp .loginForm .formfield .loginBtn1 {width: 100%;}

}

@media (max-width:525px) {

    .loginWrapp .loginContainer .loginForm .input-field {width: 100%;}

}