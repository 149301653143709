.cartcard {
  margin-left: 5rem;
}
.Cart-content-section {
  display: flex;
}
.cart-content-main {
  width: 8rem;
}
.cart-content-left h4 {
  font-family: "Poppins", serif;
  font-weight: 500;
  font-size: 15px;
  margin-left: 2rem;
  width: 24rem;
}
.cart-content-quantity {
  display: flex;
  margin-left: 2rem;
  width: 5rem;
  border: 1px solid grey;
  border-radius: 5px;
  margin-top: 3rem;
}
.cart-content-quantity p {margin-bottom: 0;}
.cart-content-quantity button {
  width: 3rem;
  border: none;
  outline: none;
  background-color: white;
  font-size: 20px;
  color: grey;
}
h4.cart-price {
  font-size: 21px;
  font-weight: 600;
}
button.cart-remove-btn {
  border: none;
  background: transparent;
  color: grey;
}
.cart-content-quantity p {
  padding: 7px;
  background-color: white;
}
.cart-content-right {
  margin-left: 5rem;
  margin-top: 5px;
}
.right-btn-img {
  width: 30px;
  height: 30px;
}
.cart-right-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
  margin-left: -0.5rem;
  cursor: pointer;
  color: grey;
}

/* .cartcard hr {
  margin-top: 1.5rem;
} */
.table-responsive-xs .table {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.table-responsive-xs .table .cartcard {
  margin-left: 0;
}

.table-responsive-xs .table tbody {
  width: 100%;
}

.table-responsive-xs .table tbody .cart-avatar {
 
  display: block;
  width: 36%;
}

.table-responsive-xs .table tbody .cart-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card-body > .btn.btn-primary {
  width: 100%;
  background-color: #3686f7;
  color: #fff;
  border: 1px solid #3686f7;
  transition: all 0.4s ease-in-out;
}
.card-body > .btn.btn-primary:hover{
  color: #3686f7;
    background-color: transparent;
    border: 1px solid #3686f7;
}

button.button-minus.btn.btn-primary.btn:hover{
  background-color: #3686f7;
}
button.button-plus.btn.btn-primary.btn:hover{
  background-color: #3686f7;
}

.billingclass .cart-items > div:not(:last-child) label {
  display: none;
}
.billingclass .cart-items > div{
  margin-bottom: 10px;
}
.billingclass .cart-items{padding-inline: 10px;}

.billingclass .card-header{background-color: transparent; border-bottom: none; padding-left: 0 !important; font-weight: 600; font-size: 20px; margin-bottom: 10px;}
.billingclass .card-body{padding: 0 !important;}
.billingclass {padding: 1.2rem;}

.shippingclass .cart-items > div label {display: none;}
.shippingclass .cart-items > div{margin-bottom: 10px;}
.shippingclass .cart-items{padding-inline: 10px;}

.shippingclass .card-header{background-color: transparent; border-bottom: none; padding-left: 0 !important; font-weight: 600; font-size: 20px; margin-bottom: 10px;}
.shippingclass .card-body{padding: 0 !important;}
.shippingclass {padding: 1.2rem;}

.lower.similar-cart .data {
  display: flex;
  flex-wrap: wrap;
}
.lower.similar-cart .product-card {
  margin-left: 0;
  margin-right: 2rem;
}

.lower.similar-cart .product-card:last-child {
  margin-right: 0;
}
.customer-login label {display: none;}
.cart-c{padding-block: 2rem;}

.cart-c  .card-header{
  background-color: transparent; border-bottom: none;font-weight: 400; font-size: 20px; margin-bottom: 10px;
}
.cart-c .card-body{padding-block: 0px !important;}
.cart-c {padding-inline: 7px;}
/* .table>:not(caption)>*>* {
  border: none;
} */

/* .table-responsive-xs .table tbody .cart-content-left {
  width: 24%;
} */

.table-responsive-xs .table tbody .cart-content-right {
  width: 24%;
  margin: 0 0 0 auto;
  display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
}

.cart-c .table tfoot tr:last-child {
  border-bottom: 1px solid #fff !important;
}